import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const orderSchema = yup.object({
  contact: yup.string().required('required'),
  vehicle: yup.object({
    year: yup.number().required('required'),
    make: yup.string().required('required'),
    model: yup.string().required('required'),
    insurance: yup.string().required('required')
  }),
  shop: yup.string().required('required'),
  customerName: yup.string().required("required")
});

export const shopSchema = yup.object({
  name: yup.string().required('required'),
  address: yup.object({
    street: yup.string().required('required'),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required')
  }),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'Phone number is not valid')
    .max(12, 'Phone number is not valid')
    .required('required'),
  notes: yup
    .string()
    .max(200, 'Shop notes must be less than 200 characters')
    .optional()
});

export const regionSchema = yup.object({
  address: yup.object({
    street: yup.string().required('required'),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required')
  }),
  name: yup.string().required('required'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'Phone number is not valid')
    .max(12, 'Phone number is not valid')
});
