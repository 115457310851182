import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function RegionDetails({ className, ...rest }) {
  const classes = useStyles();
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched
  } = useFormikContext();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Region details" />
      <CardContent>
        <div className={classes.formGroup}>
          <TextField
            fullWidth
            label="Region name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={touched.name && !!errors.name}
            helperText={touched.name ? errors.name : ''}
            variant="outlined"
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            fullWidth
            label="Region phone number"
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            error={touched.phone && !!errors.phone}
            helperText={touched.phone ? errors.phone : ''}
            variant="outlined"
          />
        </div>
      </CardContent>
    </Card>
  );
}

RegionDetails.propTypes = {
  className: PropTypes.string
};

export default RegionDetails;
