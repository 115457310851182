import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';
import { useFirestore } from 'react-redux-firebase';

const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;

if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp({
    apiKey: 'AIzaSyChhKgsxrFZb-zpI9UgtEx-pUMWKFPBDxs',
    authDomain: 'techrepair-tdc.firebaseapp.com',
    projectId: 'techrepair-tdc',
    storageBucket: 'techrepair-tdc.appspot.com',
    messagingSenderId: '741915654698',
    appId: '1:741915654698:web:adeb2caf94db7889a3a153'
  });
  firebase.firestore();
}

export default firebase;

export function useAssignJob() {
  const firestore = useFirestore();

  return [
    async (regionId, orderId, oldTechId, newTechId) => {
      newTechId = newTechId === 'Unassigned' ? null : newTechId;
      oldTechId = oldTechId === 'Unassigned' ? null : oldTechId;

      const batch = firestore.batch();
      const jobRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      if (oldTechId) {
        const oldTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(oldTechId);
        batch.update(oldTechRef, {
          jobs: arrayRemove(orderId)
        });
      }

      if (newTechId) {
        const newTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(newTechId);
        batch.update(newTechRef, {
          jobs: arrayUnion(orderId)
        });
      }

      batch.update(jobRef, {
        assignee: newTechId
      });

      await batch.commit();
    }
  ];
}

export function useDeleteOrder(regionId, { onComplete }) {
  const firestore = useFirestore();

  return [
    async orderId => {
      const batch = firestore.batch();
      const orderRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      const snap = await firestore
        .collection(`/regions/${regionId}/techs`)
        .where('jobs', 'array-contains', orderId)
        .get();
      snap.forEach(doc => {
        console.log('removing from doc');
        batch.update(doc.ref, {
          jobs: arrayRemove(orderId)
        });
      });

      batch.delete(orderRef);

      await batch.commit();

      if (onComplete) {
        onComplete();
      }
    }
  ];
}
