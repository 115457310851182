import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const states = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

function RegionAddress({ className, ...rest }) {
  const classes = useStyles();
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched
  } = useFormikContext();

  const getError = key =>
    (touched.address || {})[key] && (errors.address || {})[key];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Region address" />
      <CardContent>
        <div className={classes.formGroup}>
          <TextField
            fullWidth
            label="Street"
            name="address.street"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address?.street}
            error={!!getError('street')}
            helperText={getError('street')}
            variant="outlined"
          />
        </div>
        <div className={classes.formGroup}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="address.city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address?.city}
                error={!!getError('city')}
                helperText={getError('city')}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                select
                SelectProps={{ native: true }}
                label="State"
                name="address.state"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address?.state}
                error={!!getError('state')}
                helperText={getError('state')}
                variant="outlined"
              >
                <option></option>
                {states.map(state => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Zip"
                name="address.zip"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address?.zip}
                error={!!getError('zip')}
                helperText={getError('zip')}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
}

RegionAddress.propTypes = {
  className: PropTypes.string
};

export default RegionAddress;
