import {Button, Grid, TextField, Typography, Menu} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputMask from 'react-input-mask'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {useSelector} from "react-redux";
import firebase from "../../firebase";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {},
  calendar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarButton: {
    backgroundColor: theme.palette.common.white
  },
  form: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10
  },
  notificationBtn: {
    marginRight: 10
  },
  checkbox: {
    marginLeft: 20
  },
  saveButton: {
    minWidth: 'auto',
    marginTop: 15
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  orderIcon: {
    marginRight: theme.spacing(1)
  }
}));

function Header({ className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [disabled, setDisabled] = useState(false);
  const open = Boolean(selectEdge);
  const [formState, setFormState] = useState({
    phoneNumber: '',
    isNotified: false,
  });
  const auth = useSelector(state => state.firebase.auth);
  const isNotified = useSelector(state => state.firebase.profile.token.claims.isNotified);

  useEffect(() => {
    setFormState((e) => {return  {...e, phoneNumber: auth.phoneNumber, isNotified: isNotified}})
  },[auth.phoneNumber, isNotified]);


  const handleCalendarChange = date => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    setCalendarDate(moment());

    if (selectEdge === 'start') {
      setStartDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      }
    }

    setSelectEdge(null);
  };

  const handleSave = event => {
    event.preventDefault();
    const updateAdminNotifications = firebase.functions().httpsCallable('updateAdminNotifications');
    let data = {
      phoneNumber: formState.phoneNumber ? formState.phoneNumber : null,
      isNotified: formState.isNotified,
      uid: auth.uid
    }
    return updateAdminNotifications(data).then(() => {
      enqueueSnackbar('Notifications has updated successfully');
    })
    .catch((err) => {
      enqueueSnackbar(err.message);
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(prevFormState => ({
      ...prevFormState,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
    }));
    if (event.target.name === 'phoneNumber') {
      if (!event.target.value && formState.isNotified) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    } else if (event.target.name === 'isNotified') {
      if (!formState.phoneNumber && event.target.checked) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotification = Boolean(anchorEl);

  const handleOpenNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between">
        <Grid item lg={8} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Home
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Dashboard
          </Typography>
        </Grid>
        <Grid className={classes.calendar} item lg={3} xs={12}>
          <Button className={classes.notificationBtn} aria-controls="fade-menu" aria-haspopup="true" onClick={handleOpenNotification}>
            <NotificationsIcon />
          </Button>
          <Menu
            id="fade-menu"
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={openNotification}
            onClose={handleCloseNotification}
          >
            <List>
              <ListItem>
                <form
                  {...rest}
                  className={classes.form}
                  onSubmit={handleSave}
                >
                  <Grid container alignItems="center">
                    <Grid item>
                      <InputMask
                        mask="+1\ 999 999 9999"
                        value={formState.phoneNumber || ''}
                        disabled={false}
                        onChange={handleChange}
                        maskChar=" "
                      >
                        {() =>  <TextField
                          label="Phone number"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={formState.phoneNumber || ''}
                          variant="standard"
                        />}
                      </InputMask>
                    </Grid>
                    <Grid item>
                      <FormControlLabel label="" control={<Checkbox checked={formState.isNotified} className={classes.checkbox} name="isNotified" color="default" onChange={handleChange} value={formState.isNotified} />}/>
                    </Grid>
                  </Grid>

                  <Button
                    className={classes.saveButton}
                    type="submit"
                    color="primary"
                    variant="outlined"
                    disabled={disabled}
                  >
                    <SaveIcon/> Save
                  </Button>
                </form>
              </ListItem>
            </List>
          </Menu>
          <Button
            color="primary"
            component={RouterLink}
            to="/orders/create"
            variant="outlined"
          >
            <AddIcon className={classes.orderIcon} />
            New Order
          </Button>
        </Grid>
      </Grid>
      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: 'none' }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
